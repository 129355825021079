import { AppIcon, AppIconType } from 'common';
import cx from 'classnames';
import React, { FC } from 'react';
import { siteData } from '../../data/site-data';
import { MainContent } from '../layout/main-content';

export const OurClientsList: FC = () => {
  /**
   * DOM
   */
  return (
    <div className={cx('flex flex-col justify-center items-center bg-white')}>
      {siteData.ourClientPage.sectionClients.map(item => {
        return (
          <MainContent width="max-w-xl">
            <div className="flex flex-col items-center py-20">
              <AppIcon icon={item.imageName as AppIconType} size={64} />
              <p
                className={
                  'ff-secondary text-center text-primary whitespace-pre-line typo-h3 py-3 lg:py-6'
                }
              >
                {item.title}
              </p>
              {item.description}
            </div>
          </MainContent>
        );
      })}
    </div>
  );
};
