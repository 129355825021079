import React from 'react';
import { SectionImageContentComponent } from '../shared';
import { SectionTextContent } from '../shared';
import { MainContent } from '../layout/main-content';
import { siteData } from '../../data/site-data';
import { SectionImage, SectionImageType } from '../shared/section-image';

export const OurClientHero = () => {
  /**
   * DOM
   */
  return (
    <div className="bg-accent-light py-8 md:py-12 lg:py-20">
      <MainContent>
        <SectionImageContentComponent
          fgImage={
            <SectionImage
              name={siteData.ourClientPage.hero.imageName as SectionImageType}
              alt={siteData.ourClientPage.hero.title}
              size="big"
            />
          }
          fgImageMobile={
            <SectionImage
              name={siteData.ourClientPage.hero.imageMobileName as SectionImageType}
              alt={siteData.ourClientPage.hero.title}
              size="small"
            />
          }
          content={
            <SectionTextContent
              cls="items-center lg:mb-12"
              title={siteData.ourClientPage.hero.title}
              titleCls={'ff-secondary text-center md:text-left text-primary'}
              description={siteData.ourClientPage.hero.description}
              descriptionCls={
                'text-grey-darker text-center md:text-left md:text-xl text-base'
              }
            />
          }
        />
      </MainContent>
    </div>
  );
};
