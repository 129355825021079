import React, { FC } from 'react';
import { PageLayout } from '../components/layout';
import { OurClientHero, OurClientsList } from '../components/our-clients';

const Page: FC = () => {
  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <OurClientHero />
      <OurClientsList />
    </PageLayout>
  );
};

export default Page;
